import { gql } from '@apollo/client';

export const ADD_ASSET_BROKER = gql`
  mutation AddAssetDetails(
    $assetId: Int!
    $crexiDefaultBrokers: [CrexiDefaultBrokersInput]
    $status: String!
    $projectPlan: String!
    $userInstructions: JSON
  ) {
    addAssetDetails(
      assetId: $assetId
      crexiDefaultBrokers: $crexiDefaultBrokers
      status: $status
      projectPlan: $projectPlan
      userInstructions: $userInstructions
    )
  }
`;

export const ADD_NEW_BROKER_TO_ASSET = gql`
  mutation AddNewBrokerToAsset($id: Int!, $email: String!) {
    addNewBrokerToAsset(id: $id, email: $email)
  }
`;
