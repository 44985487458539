import FormikInputField from 'components/Fields/FormikInputField';
import { Field, Form } from 'formik';
import React from 'react';
import { Col, InputGroup, Row } from 'reactstrap';

const AuctionDatesForm = () => {
  const auctionField = () => {
    const auctionInput = [1, 2, 3, 4];
    return auctionInput.map((num) => (
      <Col md={12} key={`auction${num}`}>
        <Row className="search-result-header">
          <Col md="12">
            <InputGroup size="medium">
              <Field
                id={`auction.auction_${num}`}
                name={`auction.auction_${num}`}
                type="text"
                component={FormikInputField}
                placeholder={`Auction ${num}`}
              />
            </InputGroup>
          </Col>
        </Row>
      </Col>
    ));
  };

  return (
    <Form>
      <Row>
        <Col className="px-0 ">
          <Col className="d-flex justify-content-between my-3">
            <h6 className="mb-0 font-size-18">Auction Dates</h6>
            <span className="font-size-12">30 Character Limit</span>
          </Col>
          {auctionField()}
        </Col>
      </Row>
    </Form>
  );
};

export default AuctionDatesForm;
