import React, { useContext, useState } from 'react';
import { Button, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import BrokerCard from './components/Brokercard';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import PageLoader from 'pages/Search/common/PageLoader';
import CreateBroker from '../CreateBroker';
import CrexiBrokerCard from './components/CrexiBrokerCard';
import NoData from 'common/NoData';
import { generateUUID } from 'utils/helper';

const Brokers = () => {
  const {
    loading,
    error,
    refetchAssetDetail,
    assetDetail: getAssetDetails,
  } = useContext(assetDetailContext);

  if (loading) return <PageLoader />;
  if (error) return <div>{error.message}</div>;

  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [modal, setModal] = useState(false);

  const toggleDialog = () => setModal(!modal);

  const { asset, crexiDefaultBrokers } = getAssetDetails || [];

  const brokersTab = () => {
    const brokerTabsName = [
      { id: '1', label: 'CREXI Broker' },
      { id: '2', label: 'CREXI Default Broker' },
    ];
    return (
      <Nav tabs className="mb-3">
        {brokerTabsName?.map((tab) => (
          <NavItem key={tab.id}>
            <NavLink
              className={`font-size-14 ${activeTab === tab.id ? 'active' : ''}`}
              onClick={() => toggle(tab.id)}
            >
              {tab.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-between mt-3 mb-2">
        <h6 className="mb-0 font-size-18">Brokers</h6>
        <Button color="primary" size="sm" onClick={toggleDialog}>
          Add Brokers
        </Button>
      </div>
      <div className="brokers-content">
        <TabContent
          activeTab={activeTab}
          className="mb-2 overflow-auto"
          style={{ height: 'calc(100vh - 160px)' }}
        >
          {brokersTab()}
          <TabPane tabId="1">
            {asset?.brokers?.length > 0 ? (
              asset.brokers.map((brokerData) => (
                <BrokerCard
                  brokerData={brokerData}
                  key={brokerData.id}
                  refetch={refetchAssetDetail}
                />
              ))
            ) : (
              <NoData customMessage="No broker available" />
            )}
          </TabPane>
          <TabPane tabId="2">
            {crexiDefaultBrokers?.length > 0 ? (
              crexiDefaultBrokers.map((brokerData) => (
                <CrexiBrokerCard
                  key={generateUUID()}
                  brokerData={brokerData}
                  refetch={refetchAssetDetail}
                />
              ))
            ) : (
              <NoData customMessage="No broker available" />
            )}
          </TabPane>
        </TabContent>
        <CreateBroker
          toggleDialog={toggleDialog}
          modal={modal}
          id={asset?.id}
          refetch={refetchAssetDetail}
        />
      </div>
    </div>
  );
};
export default Brokers;
