import { useMutation } from '@apollo/client';
import FileImage from 'assets/image-line.svg';
import axios from 'axios';
import { APP_TOKEN } from 'constants/appConstants';
import { filter } from 'lodash';
import { PILOT_ASSET_UPLOADED } from 'pages/Pilot/PilotPortalGraphQL';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  getExtensionFromFileName,
  parseToInteger,
  updateFileNameWithVersion,
} from 'utils/helper';
import {
  FILE_FORMATS,
  IMAGE_FORMATS,
  VIDEO_FORMATS,
} from '../../constants/fileFormatAndTypes';
import UploadFileRows from './components/UploadFileRows';
import {
  GET_AWS_UPLOAD_ID,
  GET_UPLOAD_SIGNED_URL,
  UPLOAD_FILE_BY_PART,
  UPLOAD_FILE_IN_FOLDERS,
} from './uoloadsGraphQL';

const FileUploader = ({
  uploadLocation,
  projectId,
  refetchAssets,
  forPilotPortal,
  refetchPilot,
  pilotID,
  forPilotRegistration,
  setAvatar,
  forSalesPerson,
  toggleModal,
  versionSuffix,
  isVideoPortal,
  isVidPitchChangeImage,
  isCustomShots,
  accept,
  forSalesPortal,
}) => {
  let fileNameWithVersions = {};
  let parentFolderName = uploadLocation.split('/');
  parentFolderName = parentFolderName[parentFolderName.length - 1];

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const fileInput = React.useRef();
  const promisesArray = [];
  let partCountWithFileNameArray = [];

  const filesToUpload = (fileInput.current && fileInput.current.files) || [];

  const deleteFile = (url) => {
    const data = {
      file: url,
    };
    const config = {
      responseType: 'blob',
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_ENDPOINT}api/s3-file-delete?access_code=${process.env.REACT_APP_ACCESS_CODE}`,
        data,
        config
      )
      .then(async ({ message }) => {
        toast.success(message);
      })
      .catch(() => {
        deleteFile(url);
      });
  };

  const verifyUserLoggedIn = () => {
    return JSON.parse(window.localStorage.getItem(APP_TOKEN));
  };

  const [pilotAssetUploaded] = useMutation(PILOT_ASSET_UPLOADED, {
    onCompleted: async () => {
      await refetchPilot();
    },
  });

  const [uploadFileInFolder] = useMutation(UPLOAD_FILE_IN_FOLDERS, {
    onError: (e) => {
      if (verifyUserLoggedIn()) toast.error(e.message);
    },
  });

  const [uploadFileByPart] = useMutation(UPLOAD_FILE_BY_PART, {
    onError: (e) => {
      if (verifyUserLoggedIn()) toast.error(e.message);
    },
  });

  const verifyFormat = (file) => {
    if (!isCustomShots) {
      const ext = getExtensionFromFileName(file.name);
      let ALLOWED_FORMATS = FILE_FORMATS;
      if (setAvatar) {
        ALLOWED_FORMATS = IMAGE_FORMATS;
      } else if (isVidPitchChangeImage) {
        ALLOWED_FORMATS = IMAGE_FORMATS;
      } else if (isVideoPortal) {
        ALLOWED_FORMATS = VIDEO_FORMATS;
      }
      return Object.values(ALLOWED_FORMATS).filter((item) => {
        if (item === ext) {
          return true;
        }
        return false;
      });
    }
    return [true];
  };

  const onCompleteUpload = async (selectedFile, data) => {
    try {
      setProgress(100);
      setLoading(false);
      toggleModal();
      if (forPilotRegistration || forSalesPortal) {
        setAvatar(data.uploadFileByPart.Location.split('?')[0]);
      }
      if (verifyUserLoggedIn()) {
        toast.success(`File Uploaded Successfully.`);

        if (!forPilotPortal && !forSalesPerson && !forSalesPortal) {
          try {
            await uploadFileInFolder({
              variables: {
                fileName: fileNameWithVersions[selectedFile.name],
                fileSize: selectedFile.size.toString(),
                fileType: selectedFile.type,
                assetLink: data.uploadFileByPart.Location,
                projectId,
                parentFolderName,
              },
            });
          } catch (e) {
            if (verifyUserLoggedIn()) toast.error(e.message);
          }
        }
        if (
          forPilotPortal &&
          !forPilotRegistration &&
          !forSalesPerson &&
          !forSalesPortal
        ) {
          await pilotAssetUploaded({
            variables: {
              id: pilotID,
              isAssetUploaded: true,
            },
          });
        }
        if (!forPilotRegistration && !forSalesPortal) {
          await refetchAssets();
        }
      } else {
        deleteFile(data.uploadFileByPart.Key);
      }
    } catch (e) {
      if (verifyUserLoggedIn()) toast.error(e.message);
    }
  };

  const completeUpload = async (
    promisesArray,
    selectedFile,
    uploadId,
    fileName
  ) => {
    const uploadPartsArray = [];
    promisesArray.forEach((resolvedPromise, index) => {
      uploadPartsArray.push({
        ETag: resolvedPromise.uploadResp.headers.etag,
        PartNumber: resolvedPromise.partNumber,
      });
    });

    uploadPartsArray.sort((a, b) => {
      return a.PartNumber - b.PartNumber;
    });
    const { data } = await uploadFileByPart({
      variables: {
        path: `${uploadLocation}/${fileName}`,
        parts: [...uploadPartsArray],
        uploadId,
      },
    });

    if (data) {
      await onCompleteUpload(selectedFile, data);
    }
  };

  const uploadToPreSignedURL = async (
    preSignedURL,
    selectedFile,
    uploadId,
    partCounts,
    blob,
    partNumber
  ) => {
    try {
      const uploadResp = await axios.put(preSignedURL, blob, {
        headers: { 'Content-Type': selectedFile.type },
      });
      if (filesToUpload.length === 1) {
        promisesArray.push({ uploadResp, partNumber });
        await Promise.all(promisesArray);
        setTimeout(3000);
        if (promisesArray.length === partCounts - 1) {
          await completeUpload(
            promisesArray,
            selectedFile,
            uploadId,
            fileNameWithVersions[selectedFile.name]
          );
        }
      } else {
        promisesArray.push({
          uploadResp,
          partNumber,
          fileName: fileNameWithVersions[selectedFile.name],
        });
        await Promise.all(promisesArray);
        setTimeout(3000);
        let totalParts = 0;
        let count = 0;
        partCountWithFileNameArray.forEach((value) => {
          count += 1;
          totalParts += Object.values(value)[0][0];
        });
        if (promisesArray.length === totalParts - count) {
          partCountWithFileNameArray.forEach(async (value) => {
            const fileName = Object.keys(value)[0];
            const selectedFiles = filter(promisesArray, (o) => {
              return o.fileName === fileName;
            });
            await completeUpload(
              selectedFiles,
              selectedFile,
              Object.values(value)[0][1],
              fileName
            );
          });
        }
      }
    } catch (err) {
      if (verifyUserLoggedIn()) toast.error(err.message);
    }
  };

  const [getUploadSignedURL] = useMutation(GET_UPLOAD_SIGNED_URL, {
    onError: (e) => {
      if (verifyUserLoggedIn()) toast.error(e.message);
    },
  });

  const getPreSignedURL = async (
    uploadId,
    index,
    selectedFile,
    partCounts,
    blob
  ) => {
    try {
      const { data } = await getUploadSignedURL({
        variables: {
          path: `${uploadLocation}/${fileNameWithVersions[selectedFile.name]}`,
          partNumber: index,
          uploadId,
        },
      });
      if (data) {
        await uploadToPreSignedURL(
          data.getUploadSignedUrl,
          selectedFile,
          uploadId,
          partCounts,
          blob,
          index
        );
      }
    } catch (err) {
      if (verifyUserLoggedIn()) toast.error(err.message);
    }
  };

  const calculateChunkSize = (size) => {
    const FILE_SIZE = parseToInteger(size);
    let chunkSize;
    if (FILE_SIZE <= 20000000) {
      chunkSize = FILE_SIZE;
    } else if (FILE_SIZE > 20000000 && FILE_SIZE <= 100000000) {
      chunkSize = 20000000; //20MB
    } else if (FILE_SIZE > 100000000 && FILE_SIZE <= 500000000) {
      chunkSize = 100000000; //100MB
    } else if (FILE_SIZE > 500000000 && FILE_SIZE <= 1000000000) {
      // .5 Gb to 1Gb
      chunkSize = 500000000; //0.5Gb
    } else if (FILE_SIZE > 1000000000 && FILE_SIZE <= 5000000000) {
      // 1Gb to 5Gb
      chunkSize = 1000000000; //1GB
    } else if (FILE_SIZE > 5000000000 && FILE_SIZE <= 10000000000) {
      // 5Gb to 10Gb
      chunkSize = 2000000000; //2GB
    } else if (FILE_SIZE > 10000000000 && FILE_SIZE <= 50000000000) {
      // 10Gb to 50Gb
      chunkSize = 5000000000; //5GB
    } else if (FILE_SIZE > 50000000000 && FILE_SIZE <= 5000000000000) {
      // 50Gb to 5Tb
      chunkSize = 10000000000; //10GB
    } else {
      chunkSize = 10000000; //10MB
    }
    return chunkSize;
  };

  const getUploadURL = async (uploadId, selectedFile) => {
    try {
      const FILE_CHUNK_SIZE = calculateChunkSize(selectedFile.size);
      const fileSize = selectedFile.size;
      const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE);
      let start;
      let end;
      let blob;

      const partCounts = NUM_CHUNKS + 1;
      const partArr = Array(partCounts).fill('part');
      partCountWithFileNameArray = [
        ...partCountWithFileNameArray,
        { [fileNameWithVersions[selectedFile.name]]: [partCounts, uploadId] },
      ];
      partArr.forEach(async (item, index) => {
        if (index !== 0) {
          start = (index - 1) * FILE_CHUNK_SIZE;
          end = index * FILE_CHUNK_SIZE;
          blob =
            index < NUM_CHUNKS
              ? selectedFile.slice(start, end)
              : selectedFile.slice(start);
          await getPreSignedURL(
            uploadId,
            index,
            selectedFile,
            partCounts,
            blob
          );
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const [getAwsUploadId] = useMutation(GET_AWS_UPLOAD_ID, {
    onError: (e) => {
      if (verifyUserLoggedIn()) toast.error(e.message);
    },
  });

  const uploadFile = async (file, fileType) => {
    try {
      const { data } = await getAwsUploadId({
        variables: {
          path: `${uploadLocation}/${fileNameWithVersions[file.name]}`,
          fileType,
        },
      });
      if (data) {
        await getUploadURL(data.getAwsUploadId, file);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleChange = (event) => {
    const handleUpload = async (file, index) => {
      let suffix = 0;
      if (index !== 0) {
        suffix = versionSuffix + 1 + index;
      } else {
        suffix = versionSuffix + 1;
      }
      fileNameWithVersions = {
        ...fileNameWithVersions,
        [file.name]: updateFileNameWithVersion(suffix, file.name),
      };
      await uploadFile(file, file.type);
    };

    const renderProgressModal = () => {
      setLoading(true);
      setProgress(0);
      event.preventDefault();
    };

    if (!fileInput.current.files || fileInput.current.files.length === 0) {
      toast.error('Choose at least one file!');
      event.preventDefault();
    } else if (setAvatar && fileInput.current.files.length > 1) {
      toast.error('Multi file uploading not allowed here.');
      event.preventDefault();
    } else {
      const newArr = fileInput.current.files;
      let showProgressModal = false;
      Object.values(newArr).forEach(async (fileItem, index) => {
        const isAllowedType = verifyFormat(fileItem);
        if (isAllowedType.length) {
          showProgressModal = true;
          await handleUpload(fileItem, index);
        } else {
          toast.error(
            `${getExtensionFromFileName(
              fileItem.name
            )} file format not allowed.`
          );
        }
      });

      if (showProgressModal) {
        renderProgressModal();
      }
    }
  };
  return (
    <>
      <div className="container">
        <form className="upload-steps">
          <div className="upload-section text-center">
            <div className="input-file">
              <div className="input-group mb-3">
                <input
                  type="file"
                  className="form-control"
                  multiple
                  accept={accept}
                  ref={fileInput}
                  disabled={loading}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="upload-section-abs">
              <img src={FileImage} alt="File" />
              <h6 className="mt-3">
                Drop files here or <span>Browse</span>
              </h6>
            </div>
          </div>
        </form>
        <div>
          <UploadFileRows
            progress={progress}
            filesToUpload={Object.values(filesToUpload) || []}
            verifyFormat={verifyFormat}
          />
        </div>
      </div>
    </>
  );
};

export default FileUploader;
