import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  Row,
  InputGroup,
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import { useSelector } from 'react-redux';

import FormikInputField from 'components/Fields/FormikInputField';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { generateUUID } from 'utils/helper';
import FileUploader from 'common/FileUploader';
import { createBrokerSchema } from '../../validationschema';
import { ADD_ASSET_BROKER } from '../../graphQl';

const CreateBroker = ({ toggleDialog, modal, id, refetch, brokerData }) => {
  const { plan } = useSelector((state) => state.salesPortal);

  const initialValues = {
    firstname: brokerData?.firstName || '',
    lastname: brokerData?.lastName || '',
    affiliation: brokerData?.affiliation || '',
    title: brokerData?.title || '',
    phone: brokerData?.phone || '',
    email: brokerData?.email || '',
    company: brokerData?.company || '',
    companyUrl: brokerData?.companyUrl || '',
    marketArea: brokerData?.marketArea || '',
  };

  const [UUID, setUUID] = useState('');
  const [addBroker, { loading }] = useMutation(ADD_ASSET_BROKER);
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [imageType, setImageType] = useState('image');
  const [image, setImage] = useState({
    preview: '',
  });
  const [logo, setLogoImage] = useState({
    logoPreview: '',
  });

  const toggleModal = (type) => {
    if (type === 'logo') {
      setImageType('logo');
      setUploadFileModal(!uploadFileModal);
    } else {
      setImageType('image');
      setUploadFileModal(!uploadFileModal);
    }
  };
  const onSubmit = async (values) => {
    try {
      await addBroker({
        variables: {
          crexiDefaultBrokers: [
            {
              email: values.email,
              firstName: values.firstname,
              lastName: values.lastname,
              phone: values.phone,
              title: values.title,
              company: values.company,
              companyUrl: values.companyUrl,
              marketArea: values.marketArea,
              affiliation: values.affiliation,
              uuid: UUID,
              imageUrl: image.preview,
              companyLogoUrl: logo.logoPreview,
            },
          ],
          assetId: id,
          status: 'Inprogress',
          projectPlan: plan?.type ? plan.type : 'PLATINUM',
        },
      });
      await refetch();
      toggleDialog();
      toast.success('Broker added successfully');
    } catch (err) {
      toast.error('Error adding broker:', err);
    }
  };

  const setAvatar = (avatarImgSignedURL) => {
    if (imageType === 'image') {
      setImage({
        preview: avatarImgSignedURL,
      });
    } else {
      setLogoImage({
        logoPreview: avatarImgSignedURL,
      });
    }
  };

  useEffect(() => {
    const resetState = () => {
      setImage({ preview: '' });
      setLogoImage({ logoPreview: '' });
      setUUID(generateUUID());
    };

    resetState();
  }, [modal]);

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggleDialog}
        className="project-Folders-spp mr-0 mt-0 mb-0 project-details-spp project-details-bronze"
      >
        <ModalHeader toggle={toggleDialog}>Broker Details</ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={createBrokerSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {() => (
            <Form>
              <ModalBody
                style={{ height: 'calc(100vh - 128px)' }}
                className="p-0 overflow-auto"
              >
                <div
                  style={{ height: '91px', background: '#F1EFF4' }}
                  className="d-flex align-items-center justify-content-end p-4"
                >
                  {image.preview || brokerData?.imageUrl ? (
                    <img
                      src={image.preview || brokerData?.imageUrl}
                      alt="broker pic"
                      className="admin-icon border-0 p-0"
                    />
                  ) : (
                    <div className="admin-icon">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  )}

                  {!brokerData?.imageUrl && (
                    <Button
                      color="primary"
                      onClick={toggleModal}
                      className="btn-sm px-4 mr-0 mt-2"
                      disabled={brokerData?.uuid}
                    >
                      Upload Profile Image
                    </Button>
                  )}
                </div>
                <div className="p-4 mt-5">
                  <Row>
                    <Col md={6}>
                      <InputGroup size="medium">
                        <Field
                          id="firstname"
                          name="firstname"
                          type="text"
                          component={FormikInputField}
                          placeholder="First Name"
                          label="First Name"
                          disabled={brokerData?.firstName}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={6}>
                      <InputGroup size="medium">
                        <Field
                          id="lastname"
                          name="lastname"
                          type="text"
                          component={FormikInputField}
                          placeholder="Last Name"
                          label="Last Name"
                          disabled={brokerData?.lastName}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <InputGroup size="medium">
                        <Field
                          id="affiliation"
                          name="affiliation"
                          type="text"
                          component={FormikInputField}
                          placeholder="Affiliation"
                          label="Affiliation"
                          disabled={brokerData?.affiliation || brokerData?.uuid}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={6}>
                      <InputGroup size="medium">
                        <Field
                          id="title"
                          name="title"
                          type="text"
                          component={FormikInputField}
                          placeholder="Title"
                          label="Title"
                          disabled={brokerData?.title || brokerData?.uuid}
                        />
                      </InputGroup>
                    </Col>

                    <Col md={6}>
                      <InputGroup size="medium">
                        <Field
                          id="phone"
                          name="phone"
                          type="text"
                          component={FormikInputField}
                          placeholder="Phone"
                          label="Phone"
                          disabled={brokerData?.phone}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={6}>
                      <InputGroup size="medium">
                        <Field
                          id="email"
                          name="email"
                          type="text"
                          component={FormikInputField}
                          placeholder="Email"
                          label="Email"
                          disabled={brokerData?.email}
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={4}>
                      <label className="mb-0">Company Logo</label>
                      {logo.logoPreview || brokerData?.companyLogoUrl ? (
                        <img
                          src={logo.logoPreview || brokerData?.companyLogoUrl}
                          alt=""
                          height="36"
                          className="d-block w-100 object-fit-cover mt-2"
                        />
                      ) : (
                        <Button
                          color="primary"
                          onClick={() => toggleModal('logo')}
                          className="px-4 mr-0 mt-2 w-100"
                          style={{ fontSize: '14px' }}
                          disabled={brokerData?.uuid}
                        >
                          Upload Logo
                        </Button>
                      )}
                    </Col>
                    <Col md={8}>
                      <InputGroup size="medium">
                        <Field
                          id="company"
                          name="company"
                          type="text"
                          component={FormikInputField}
                          placeholder="Company name"
                          label="Company name"
                          disabled={brokerData?.company}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <InputGroup size="medium">
                    <Field
                      id="companyUrl"
                      name="companyUrl"
                      type="text"
                      component={FormikInputField}
                      placeholder="Company Office URL"
                      label="Company Office URL"
                      disabled={brokerData?.companyUrl}
                    />
                  </InputGroup>
                  <InputGroup size="medium">
                    <Field
                      id="marketArea"
                      name="marketArea"
                      type="text"
                      component={FormikInputField}
                      placeholder="Crexi Market Area"
                      label="Crexi Market Area"
                      disabled={brokerData?.marketArea || brokerData?.uuid}
                    />
                  </InputGroup>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="cancel"
                  type="button"
                  size="sm"
                  onClick={toggleDialog}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  size="sm"
                  disabled={brokerData?.uuid || loading}
                >
                  {loading ? 'Saving' : 'Save'}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>

      <Modal
        isOpen={uploadFileModal}
        toggle={toggleModal}
        className="modal-lg modal-dialog-centered"
      >
        <ModalBody>
          <div>
            <FileUploader
              uploadLocation={`default_broker/${UUID}/${imageType}`}
              toggleModal={toggleModal}
              versionSuffix={0}
              setAvatar={setAvatar}
              forSalesPortal
              accept="image/*"
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreateBroker;
