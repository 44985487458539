import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Col,
  Row,
  InputGroup,
} from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import FormikInputField from 'components/Fields/FormikInputField';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { ADD_NEW_BROKER_TO_ASSET } from '../../graphQl';
import { editBrokerSchema } from '../../validationschema';

const AddBroker = ({ toggleDialog, modal, brokerData, refetch }) => {
  const { photoUrl, brokerages, firstName, lastName } = brokerData || {};
  const [addBroker, { loading }] = useMutation(ADD_NEW_BROKER_TO_ASSET);

  const initialValues = {
    leadBroker: false,
    name: `${firstName} ${lastName}`.trim(),
    affiliation: brokerData?.designations[0] || '',
    title: brokerData?.specialityAssetType || '',
    phone: brokerData?.phone || '',
    email: brokerData?.email || '',
    companyName: brokerData?.brokerages[0]?.name || '',
    companyUrl: brokerData?.brokerages[0]?.website || '',
    marketArea: brokerData?.brokerages[0]?.location?.city || '',
  };

  const separatedNumbers = brokerData?.phone?.split(',') || [];

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggleDialog}
        className="project-Folders-spp mr-0 mt-0 mb-0 project-details-spp project-details-bronze"
      >
        <ModalHeader toggle={toggleDialog}>Broker Details</ModalHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={editBrokerSchema}
          onSubmit={async (values) => {
            try {
              await addBroker({
                variables: {
                  id: parseInt(brokerData.id, 10),
                  email: values.email,
                },
              });
              toast.success('Broker detail updated successfully');
              await refetch();
              toggleDialog();
            } catch (err) {
              toast.error('Error adding broker:', err);
            }
          }}
          enableReinitialize
        >
          {() => (
            <Form>
              <ModalBody
                style={{ height: 'calc(100vh - 128px)' }}
                className="p-0 overflow-auto"
              >
                <div
                  style={{ height: '91px', background: '#F1EFF4' }}
                  className="d-flex align-items-center justify-content-end p-4"
                >
                  {photoUrl ? (
                    <img
                      src={photoUrl}
                      alt="broker pic"
                      className="admin-icon border-0 p-0"
                    />
                  ) : (
                    <div className="admin-icon">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  )}

                  {/* <div className="card-checkbox">
                    <FormGroup check style={{ top: '5px', left: '10px' }}>
                      <Input type="checkbox" />
                      <Label
                        check
                        className="position-relative font-size-14"
                        style={{ top: '2px' }}
                      >
                        Lead Broker
                      </Label>
                    </FormGroup>
                  </div> */}
                </div>
                <div className="p-4 mt-5">
                  <Row>
                    <Col md={6}>
                      <InputGroup size="medium">
                        <Field
                          id="name"
                          name="name"
                          type="text"
                          component={FormikInputField}
                          placeholder="Name"
                          label="Name"
                          disabled
                        />
                      </InputGroup>
                    </Col>

                    <Col md={6}>
                      <InputGroup size="medium">
                        <Field
                          id="affiliation"
                          name="affiliation"
                          type="text"
                          component={FormikInputField}
                          placeholder="Affiliation"
                          label="Affiliation"
                          disabled
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <InputGroup size="medium">
                        <Field
                          id="title"
                          name="title"
                          type="text"
                          component={FormikInputField}
                          placeholder="Title"
                          label="Title"
                          disabled
                        />
                      </InputGroup>
                    </Col>

                    <Col md={6}>
                      {separatedNumbers.map((phone, index) => (
                        <InputGroup size="medium" key={phone}>
                          <Field
                            id={`phone-${index}`}
                            name={`phone-${index}`}
                            type="text"
                            component={FormikInputField}
                            placeholder="Phone"
                            label={`Phone ${
                              separatedNumbers.length > 1 ? index + 1 : ''
                            }`}
                            defaultValue={phone}
                            disabled
                          />
                        </InputGroup>
                      ))}

                      {/* <InputGroup size="medium">
                        <Field
                          id="phone"
                          name="phone"
                          type="text"
                          component={FormikInputField}
                          placeholder="Phone"
                          label="Phone"
                          disabled
                        />
                      </InputGroup> */}
                    </Col>
                  </Row>

                  <InputGroup size="medium">
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      component={FormikInputField}
                      placeholder="Email"
                      label="Email"
                      disabled={brokerData?.email}
                    />
                  </InputGroup>

                  <Row>
                    <Col md={4}>
                      {/* <FormGroup>
                        <Label for="logo">Upload Logo</Label>
                        <Input
                          type="file"
                          name="logo"
                          onChange={(event) =>
                            setFieldValue('logo', event.currentTarget.files[0])
                          }
                        />
                      </FormGroup> */}
                      <Label for="logo">Company Logo</Label>
                      <div>
                        <img src={brokerages[0]?.logoUrl} alt="" height={34} />
                      </div>
                    </Col>
                    <Col md={8}>
                      <InputGroup size="medium">
                        <Field
                          id="companyName"
                          name="companyName"
                          type="text"
                          component={FormikInputField}
                          placeholder="Company name"
                          label="Company name"
                          disabled
                        />
                      </InputGroup>
                    </Col>
                  </Row>

                  <InputGroup size="medium">
                    <Field
                      id="companyUrl"
                      name="companyUrl"
                      type="text"
                      component={FormikInputField}
                      placeholder="Company Office URL"
                      label="Company Office URL"
                      disabled
                    />
                  </InputGroup>

                  <InputGroup size="medium">
                    <Field
                      id="marketArea"
                      name="marketArea"
                      type="text"
                      component={FormikInputField}
                      placeholder="Crexi Market Area"
                      label="Crexi Market Area"
                      disabled
                    />
                  </InputGroup>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="cancel"
                  type="button"
                  size="sm"
                  onClick={toggleDialog}
                  disabled={loading}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  size="sm"
                  disabled={loading || brokerData?.email}
                >
                  {loading ? 'Saving' : 'Save'}
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default AddBroker;
