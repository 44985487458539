import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars-2';

import Header from 'pages/Search/common/Header';
import Sidebar from 'pages/Search/common/DetailViewSidebar';
import PropertyDetail from 'pages/Search/components/PropertyDetails/components/PropertyDetail';
import CitiesWithIn200Miles from 'pages/Search/components/PropertyDetails/components/CitiesWithIn200Miles';
import CitiesIn100Miles from 'pages/Search/components/PropertyDetails/components/CalloutsIn100Miles';
import CitiesIn10Miles from 'pages/Search/components/PropertyDetails/components/CalloutsIn10Miles';
import CallToAction from 'pages/Search/components/PropertyDetails/components/CallToAction';
import InteriorVideoContact from 'pages/Search/components/PropertyDetails/components/InteriorVideoContact';
import Summary from 'pages/Search/components/PropertyDetails/components/Summary';
import AssetDetailProvider from 'contexts/AssetDetailContext';
import GetPlanProvider from 'contexts/GetPlanContext';
import PageNotFound from './components/PageNotFound';
import AuctionBrokerDetails from './components/AuctionBrokerDetails';
import PointOfIntrest from './components/PointOfIntrest';

const PropertyDetailContainer = ({ component, ...rest }) => {
  const { path } = rest.match;
  return (
    <GetPlanProvider>
      <AssetDetailProvider>
        <Container className="themed-container property-detail-page" fluid>
          <Row>
            <Col className="content-quad px-4 bg-white">
              <Header />
              <Sidebar />
              <Scrollbars
                autoHide
                className="row"
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{
                  width: `calc(100% + 30px)`,
                  height: `calc(100vh - 60px)`,
                }}
              >
                <Col sm="12">
                  <Switch>
                    <Route
                      exact
                      path={`${path}/details`}
                      component={PropertyDetail}
                    />
                    <Route
                      exact
                      path={`${path}/cities-in-200-miles`}
                      component={CitiesWithIn200Miles}
                    />
                    <Route
                      exact
                      path={`${path}/cities-in-100-miles`}
                      component={CitiesIn100Miles}
                    />
                    <Route
                      exact
                      path={`${path}/cities-in-10-miles`}
                      component={CitiesIn10Miles}
                    />
                    <Route
                      exact
                      path={`${path}/call-to-action`}
                      component={CallToAction}
                    />
                    <Route exact path={`${path}/point-of-intrest`}>
                      <PointOfIntrest />
                    </Route>
                    <Route exact path={`${path}/interior-video-contact`}>
                      <InteriorVideoContact />
                    </Route>
                    <Route
                      exact
                      path={`${path}/auction-broker-details`}
                      component={AuctionBrokerDetails}
                    />
                    <Route exact path={`${path}/summary`} component={Summary} />
                    <Route render={() => <PageNotFound />} />
                  </Switch>
                </Col>
                {/* <div style={{ height: '50px' }} /> */}
              </Scrollbars>
            </Col>
          </Row>
        </Container>
      </AssetDetailProvider>
    </GetPlanProvider>
  );
};

export default PropertyDetailContainer;
