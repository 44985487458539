import React, { useContext } from 'react';
import { Col, Row } from 'reactstrap';
import AuctionDatesForm from './components/AuctionDates';
import Brokers from './components/Brokers';
import PropertyDetailHeader from 'pages/Search/common/PropertyDetailHeader';
import { Formik } from 'formik';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import { cloneDeep } from 'lodash';
import PageLoader from 'common/PageLoader';
import { auctionSchema } from './validationschema';

const defaultValue = {
  auction_1: '',
  auction_2: '',
  auction_3: '',
  auction_4: '',
};

const FIELD_KEY = 'auctionbrokerdetails';

const AuctionBrokerDetails = () => {
  let auctionData = defaultValue;
  const {
    loading,
    error,
    refetchAssetDetail,
    assetDetail: getAssetDetails,
  } = useContext(assetDetailContext);

  if (loading) return <PageLoader />;
  if (error) return <div>{error.message}</div>;
  if (getAssetDetails) {
    if (getAssetDetails.auction) {
      auctionData = getAssetDetails.auction;
    }

    const renderForm = (props, isDisabled) => {
      return (
        <AuctionDatesForm
          {...props}
          fieldKey={FIELD_KEY}
          getAssetDetails={getAssetDetails}
          interiorVideoContactData={cloneDeep(auctionData)}
          refetch={refetchAssetDetail}
          isDisabled={isDisabled}
        />
      );
    };

    return (
      <div>
        <Formik
          initialValues={auctionData}
          enableReinitialize
          validationSchema={auctionSchema}
          children={(props) => {
            const { isValid, dirty, values } = props;
            const isDisabled = !isValid;

            return (
              <>
                <PropertyDetailHeader
                  dirty={dirty}
                  fieldKey={FIELD_KEY}
                  values={values}
                  getAssetDetails={getAssetDetails}
                  isValid
                  isDisabled={isDisabled}
                  refetch={refetchAssetDetail}
                />

                <Row>
                  <Col md={3}>{renderForm(props, values, isDisabled)}</Col>
                  <Col md={9} style={{ background: '#F5F5F5' }}>
                    <Brokers />
                  </Col>
                </Row>
              </>
            );
          }}
        />
      </div>
    );
  }
  return null;
};
export default AuctionBrokerDetails;
