import React, { useContext, useState } from 'react';
import { Button, Table, Tooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation } from '@apollo/client';
import {
  faCaretRight,
  faCaretDown,
  faSync,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useParams } from 'react-router-dom';
import { isArray } from 'lodash';
//
import {
  PROJECT_DETAIL_COLUMN,
  SYNC_STATUS_CLOSED,
} from 'pages/ManagementPortal/constant';
import { SYNC_MANAGEMENT_PORTAL_TASKS } from 'pages/ManagementPortal/components/Projects/ProjectsGraphQL';
import { BRONZE } from 'constants/appConstants';
import { authContext } from 'contexts/AuthContext';
import ActivityItems from './components/ActivityItems';

const TaskItem = ({
  sectionName = 'NA',
  missingTasks = [],
  sectionId,
  sectionTasks,
  syncRequired,
  productType,
  syncStatus,
  asanaProjectId,
  isNewTemplate,
  planName,
  refetch,
  finalDownload,
}) => {
  const { projectId } = useParams();
  const { auth } = useContext(authContext);
  const location = useLocation();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const [syncManagementPortalTasks, { loading }] = useMutation(
    SYNC_MANAGEMENT_PORTAL_TASKS,
    {
      onCompleted: async ({ syncManagementProjectTasks }) => {
        await refetch();
        toast.success(syncManagementProjectTasks.message);
      },
      onError: ({ message }) => {
        toast.error(message);
      },
    }
  );

  const idFromLocation = location.hash.split('#')[1];
  const idFromParam = projectId.split('#')[1];

  const renderSectionTasks = () => {
    let expandedTableId = [];
    if (!sectionTasks.length)
      return <div className="no-activity-message">No task found!</div>;
    return sectionTasks.map((row, index) => {
      const { task, activities } = row;
      const lastObject = activities[activities.length - 1];
      const taskID = task.split(' ').join('_');

      const taskData = [
        {
          ...row,
          ...lastObject,
          sectionName,
          planName,
          finalDownload,
          userRole: auth?.data?.role,
        },
      ];
      if ((idFromParam || idFromLocation) === taskID) {
        expandedTableId = task;
      }

      return (
        <div key={`${task}_${index + 1}`} className="expand-table-container">
          <CustomTable
            keyField="task"
            data={taskData}
            columns={PROJECT_DETAIL_COLUMN}
            classes="mb-0  project-detail-inner-table "
            id={taskID}
            expandedTableId={expandedTableId}
            planName={planName}
            finalDownload={finalDownload}
          />
        </div>
      );
    });
  };

  const syncManagementPortal = async () => {
    try {
      await syncManagementPortalTasks({
        variables: {
          asanaProjectId,
          planType: productType,
          isNewTemplate,
          sectionName,
          projectId: parseInt(projectId, 10),
        },
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const renderSectionNameWithMissingTasks = () => {
    if (syncRequired) {
      return (
        <>
          {sectionName}
          <Button
            type="button"
            color="primary"
            outline
            className="btn-sm btn px-2 font-size-10 sync-button ml-2"
            onClick={() => syncManagementPortal()}
            disabled={loading}
          >
            <FontAwesomeIcon
              icon={faSync}
              className={loading ? 'rotate ml-0' : 'ml-0'}
              title="Tasks are missing. Click to re-synchronize."
            />
          </Button>
        </>
      );
    }

    if (
      syncStatus === SYNC_STATUS_CLOSED &&
      isArray(missingTasks) &&
      missingTasks.length
    ) {
      const missingTasksText = `${missingTasks.join(
        ', '
      )} tasks are missing from template.`;

      return (
        <>
          {sectionName}
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="ml-2"
            id={`tooltip-${sectionId}`}
          />
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={`tooltip-${sectionId}`}
            toggle={toggleTooltip}
          >
            {missingTasksText}
          </Tooltip>
        </>
      );
    }

    return sectionName;
  };

  return (
    <>
      <h6 className="mt-4 mb-2 font-size-14 font-weight-bold">
        {renderSectionNameWithMissingTasks()}
      </h6>
      <div className="collapse-section">{renderSectionTasks()}</div>
    </>
  );
};

const CustomTable = ({ expandedTableId, planName, ...rest }) => {
  const expandRow = {
    parentClassName: (isExpanded) => {
      return planName !== BRONZE ? 'parent-expand-bar' : '';
    },
    renderer: ({ asanaTaskId }) => {
      return (
        <div className="">
          {planName !== BRONZE && (
            <Table className="project-detail-inner-table activity-table">
              <tbody>
                <ActivityItems asanaTaskId={asanaTaskId} planName={planName} />
              </tbody>
            </Table>
          )}
        </div>
      );
    },
    showExpandColumn: true,
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return <FontAwesomeIcon icon={faCaretDown} />;
      }
      return <FontAwesomeIcon icon={faCaretRight} />;
    },
    expanded: [expandedTableId],
  };

  return <BootstrapTable {...rest} expandRow={expandRow} />;
};

export default TaskItem;
