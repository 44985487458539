import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';

const Map = ({
  selectedPlaces,
  defaultCenter = [-98.5795, 39.8283],
  defaultZoom = 3,
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const markerRefs = useRef([]);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: defaultCenter,
        zoom: defaultZoom,
      });

      map.current.addControl(new mapboxgl.NavigationControl());
    }
  }, [defaultCenter, defaultZoom]);

  const updateMapBounds = () => {
    if (selectedPlaces.length > 0) {
      const bounds = selectedPlaces.reduce(
        (bounds, city) =>
          bounds.extend([
            parseFloat(city.intpt_long),
            parseFloat(city.intpt_lat),
          ]),
        new mapboxgl.LngLatBounds(
          [
            parseFloat(selectedPlaces[0].intpt_long),
            parseFloat(selectedPlaces[0].intpt_lat),
          ],
          [
            parseFloat(selectedPlaces[0].intpt_long),
            parseFloat(selectedPlaces[0].intpt_lat),
          ]
        )
      );

      map.current.fitBounds(bounds, {
        padding: 50,
        maxZoom: 8,
        duration: 1000,
      });
    } else {
      map.current.flyTo({
        center: defaultCenter,
        zoom: defaultZoom,
        essential: true,
      });
    }
  };

  const updateMap = () => {
    markerRefs.current.forEach((marker) => {
      console.log('marker', marker);
      const circleSourceId = `circleSource-${marker._lngLat.lat}-${marker._lngLat.lng}`;
      const circleLayerId = `circleLayer-${marker._lngLat.lat}-${marker._lngLat.lng}`;
      if (map.current.getLayer(circleLayerId)) {
        map.current.removeLayer(circleLayerId);
      }

      if (map.current.getSource(circleSourceId)) {
        map.current.removeSource(circleSourceId);
      }
      marker.remove();
    });
    markerRefs.current = [];

    selectedPlaces.forEach(
      ({ intpt_lat, intpt_long, name, area, population, ...rest }) => {
        const lng = parseFloat(intpt_long);
        const lat = parseFloat(intpt_lat);

        const marker = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .setPopup(
            new mapboxgl.Popup({ offset: 25 }) // Add an offset for better positioning
              .setHTML(`<b>${name}</b><p>Population: ${population}</p>`)
          )
          .addTo(map.current);

        // Automatically open the popup
        marker.getPopup().addTo(map.current);

        // Keep track of the marker
        markerRefs.current.push(marker);

        // // Add Marker
        // const marker = new mapboxgl.Marker()
        //   .setLngLat([lng, lat])
        //   .setPopup(
        //     new mapboxgl.Popup().setHTML(
        //       `<b>${name}</b><p>Population: ${population}</p>`
        //     )
        //   )
        //   .addTo(map.current);

        // markerRefs.current.push(marker);

        // Circle Layer and Source IDs
        const circleSourceId = `circleSource-${lat}-${lng}`;
        const circleLayerId = `circleLayer-${lat}-${lng}`;

        console.log({ circleSourceId, circleLayerId });

        if (!map.current.getSource(circleSourceId)) {
          map.current.addSource(circleSourceId, {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [lng, lat],
              },
              properties: {
                area: area || 100,
                population: population || 0,
              },
            },
          });
        }

        if (!map.current.getLayer(circleLayerId)) {
          map.current.addLayer({
            id: circleLayerId,
            type: 'circle',
            source: circleSourceId,
            paint: {
              'circle-radius': [
                'interpolate',
                ['linear'],
                ['get', 'area'],
                0,
                100,
                500,
                20,
                1000,
                40,
              ],
              'circle-color': '#ff0000',
              'circle-stroke-color': '#ff0000',
              'circle-stroke-width': 0,
              'circle-opacity': 0.18,
            },
          });
        }
      }
    );

    updateMapBounds();
  };

  useEffect(() => {
    updateMap();
  }, [selectedPlaces]);

  return (
    <div
      ref={mapContainer}
      style={{
        height: 'calc(100vh - 200px)',
        width: '100%',
      }}
    />
  );
};

export default Map;
