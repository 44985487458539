import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const Walkthrough = ({ isOpen, toggle }) => {
  const videoURL =
    'https://videoms-stage-new.s3.us-east-1.amazonaws.com/2+-+Client+Boundary+Mapping+Tool+Explainer+VIdeo.mp4';
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered size="xl">
      <ModalHeader toggle={toggle}>Sample Mapbox video</ModalHeader>
      <ModalBody className="p-0">
        <video controls autoPlay width={'100%'}>
          <source src={videoURL} type="video/mp4" />
          <track kind="captions" />
        </video>
      </ModalBody>
    </Modal>
  );
};

export default Walkthrough;
