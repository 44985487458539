import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { PROJECT_INSTRUCTIONS } from 'constants/appConstants';

const ProjectInstruction = ({ userInstructions }) => {
  return (
    <Card body className="mt-4 border-radius-8px border-0 shadow">
      <CardTitle tag="h5" className="font-size-16">
        PROJECT INSTRUCTION
      </CardTitle>
      <CardBody className="p-0">
        <div className="mb-4">
          <span className="font-weight-bold font-size-16">
            {PROJECT_INSTRUCTIONS.QUESTION1}
          </span>
          <span className="d-block font-size-14">
            - {userInstructions?.instructions || 'N/A'}
          </span>
        </div>
        <div className="mb-4">
          <span className="font-weight-bold font-size-16">
            {PROJECT_INSTRUCTIONS.QUESTION2}
          </span>
          <span className="d-block font-size-14">
            - {userInstructions?.doNotCapture || 'N/A'}
          </span>
        </div>
        <div>
          <span className="font-weight-bold font-size-16">
            {PROJECT_INSTRUCTIONS.QUESTION3}
          </span>
          <span className="d-block font-size-14">
            - {userInstructions?.otherNotes || 'N/A'}
          </span>
        </div>
      </CardBody>
    </Card>
  );
};

export default ProjectInstruction;
