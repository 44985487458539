import * as Yup from 'yup';

export const createBrokerSchema = Yup.object().shape({
  firstname: Yup.string().trim().required('First name is required'),
  lastname: Yup.string().trim().required('Last name is required'),
  phone: Yup.string()
    .required('Phone is required')
    .test('is-valid-phone', 'Phone number is invalid', (value) => {
      const phoneRegex = /^\+?[1-9]\d{1,14}$/;
      return phoneRegex.test(value);
    }),
  email: Yup.string().email('Invalid email').required('Email is required'),
  company: Yup.string().trim().required('Company name is required'),
  companyUrl: Yup.string()
    .matches(
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/,
      'Invalid URL format'
    )
    .matches(/^\S*$/, 'URL cannot contain spaces')
    .required('Website URL is required'),
});

export const auctionSchema = Yup.object().shape({
  auction: Yup.object().shape({
    auction_1: Yup.string().max(30, 'Maximum 30 characters allowed'),
    auction_2: Yup.string().max(30, 'Maximum 30 characters allowed'),
    auction_3: Yup.string().max(30, 'Maximum 30 characters allowed'),
    auction_4: Yup.string().max(30, 'Maximum 30 characters allowed'),
  }),
});

export const editBrokerSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
});
