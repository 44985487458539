import React, { useContext, useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import FileUploader from 'common/FileUploader';
import { awsFolderAssetsContext } from 'contexts/GetAWSFolderAssets';

const UploadModal = ({ upload, projectId, uploadLocation, versionSuffix }) => {
  const { refetchAssets } = useContext(awsFolderAssetsContext);

  const [uploadFileModal, setUploadFileModal] = useState(false);

  const toggleModal = () => setUploadFileModal(!uploadFileModal);

  return (
    <>
      <Button
        color="primary"
        onClick={toggleModal}
        className="btn-sm px-4 mr-3"
      >
        {upload}
      </Button>
      <Modal
        isOpen={uploadFileModal}
        toggle={toggleModal}
        className="modal-lg modal-dialog-centered"
      >
        <ModalBody>
          <div className="">
            <FileUploader
              uploadLocation={uploadLocation}
              refetchAssets={refetchAssets}
              projectId={projectId}
              toggleModal={toggleModal}
              versionSuffix={versionSuffix}
              accept="video/*"
            />
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UploadModal;
