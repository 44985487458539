import React, { useState } from 'react';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import defaultProfile from 'assets/profile-default.svg';
import CreateBroker from '../../../CreateBroker';

const CrexiBrokerCard = ({ brokerData }) => {
  const {
    firstName,
    lastName,
    specialityAssetType,
    phone,
    email,
    city,
    company,
    imageUrl,
    companyLogoUrl,
  } = brokerData;

  const [modal, setModal] = useState(false);
  const toggleDialog = () => setModal(!modal);

  return (
    <>
      <Card className="broker-card border-0 mb-2">
        <CardBody>
          <div className="d-flex w-100">
            <div>
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt={firstName}
                  className="rounded-circle object-fit-cover"
                  height={73}
                  width={73}
                />
              ) : (
                <div>
                  <img src={defaultProfile} width={73} alt="defaultProfile" />
                </div>
              )}
            </div>

            <div className="ml-3 w-100">
              <div className="d-flex justify-content-between">
                <div>
                  <h6 className="mb-0 font-size-16 mb-1">
                    {firstName} {lastName}
                  </h6>
                  <p className="mb-3 font-size-14">
                    {specialityAssetType ? specialityAssetType : 'N/A'}
                    <span className="ml-4">
                      <FontAwesomeIcon
                        icon={faMapMarker}
                        className="fa-1x mr-1"
                      />
                      {city ? city : 'N/A'}
                    </span>
                  </p>
                </div>
                <div className="d-flex justify-content-end">
                  <div>
                    <Button
                      color="link"
                      size="sm"
                      className="px-0 mr-2"
                      onClick={toggleDialog}
                    >
                      View Profile
                    </Button>
                  </div>
                </div>
              </div>
              <Row>
                <Col md={4}>
                  <p className="mr-4 font-size-14">
                    Phone No:
                    <span className="ml-1">{phone ? phone : 'N/A'}</span>
                  </p>
                </Col>

                <Col md={8}>
                  <p className="mb-0 font-size-14 d-flex">
                    Email:
                    <span
                      className="ml-1 d-block ellipsis"
                      title={email ? email : ''}
                    >
                      {email ? email : 'N/A'}
                    </span>
                  </p>
                </Col>
              </Row>
              <div
                style={{ background: '#F8F8F8' }}
                className="p-2 d-flex align-items-center rounded"
              >
                {companyLogoUrl && (
                  <img
                    src={companyLogoUrl}
                    alt="logo"
                    className="object-fit-fill"
                    height={29}
                  />
                )}

                <p className="ml-3 mb-0 font-size-14">{company}</p>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <CreateBroker
        toggleDialog={toggleDialog}
        modal={modal}
        brokerData={brokerData}
      />
    </>
  );
};

export default CrexiBrokerCard;
