import React, { useContext, useState, useEffect } from 'react';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import AddBroker from '../../../AddBroker';
import defaultProfile from 'assets/profile-default.svg';
import { Field, useFormikContext } from 'formik';
import FormikCheckboxField from 'components/Fields/FormikCheckbox';
import { assetDetailContext } from 'contexts/AssetDetailContext';

const BrokerCard = ({ brokerData, refetch }) => {
  const {
    firstName,
    lastName,
    photoUrl,
    brokerages,
    specialityAssetType,
    phone,
    email,
    city,
    id,
  } = brokerData;

  const { assetDetail } = useContext(assetDetailContext);
  const [modal, setModal] = useState(false);
  const { values, setFieldValue } = useFormikContext();

  const [isIncluded, setIsIncluded] = useState(false);
  const [isLead, setIsLead] = useState(false);

  useEffect(() => {
    if (assetDetail?.includedBrokers) {
      const broker = assetDetail.includedBrokers.find(
        (b) => b.id === parseInt(id, 10)
      );
      if (broker) {
        setIsIncluded(broker.isIncluded);
        setIsLead(broker.isLead);
      }
    }
  }, [assetDetail, id]);

  const toggleDialog = () => setModal(!modal);

  const handleChange = (e) => {
    const { name, checked } = e.target;

    if (name === 'isIncluded') {
      setIsIncluded(checked);
    }
    if (name === 'isLead') {
      setIsLead(checked);
    }

    const updatedBrokers = [...(values.includedBrokers || [])];

    const existingBrokerIndex = updatedBrokers.findIndex(
      (broker) => broker.id === parseInt(id, 10)
    );

    if (existingBrokerIndex > -1) {
      updatedBrokers[existingBrokerIndex] = {
        id: parseInt(id, 10),
        isIncluded:
          name === 'isIncluded'
            ? checked
            : updatedBrokers[existingBrokerIndex].isIncluded,
        isLead:
          name === 'isLead'
            ? checked
            : updatedBrokers[existingBrokerIndex].isLead,
      };
    } else {
      updatedBrokers.push({
        id: parseInt(id, 10),
        isIncluded: name === 'isIncluded' ? checked : false,
        isLead: name === 'isLead' ? checked : false,
      });
    }

    const filteredBrokers = updatedBrokers.filter(
      (broker) => broker.isIncluded || broker.isLead
    );

    setFieldValue('includedBrokers', filteredBrokers);
  };

  return (
    <Card className="broker-card border-0 mb-2">
      <CardBody>
        <div className="d-flex w-100">
          <div>
            {photoUrl ? (
              <img
                src={photoUrl}
                alt={firstName}
                className="rounded-circle object-fit-cover"
                height={73}
                width={73}
              />
            ) : (
              <div>
                <img src={defaultProfile} width={73} alt="defaultProfile" />
              </div>
            )}
          </div>

          <div className="ml-3 w-100">
            <div className="d-flex justify-content-between">
              <div>
                <h6 className="mb-0 font-size-16 mb-1">
                  {firstName} {lastName}
                </h6>
                <p className="mb-3 font-size-14">
                  {specialityAssetType ? specialityAssetType : 'N/A'}
                  <span className="ml-4">
                    <FontAwesomeIcon
                      icon={faMapMarker}
                      className="fa-1x mr-1"
                    />
                    {city ? city : 'N/A'}
                  </span>
                </p>
              </div>
              <div className="d-flex justify-content-end">
                <div className="mr-4 do-not-checkbox">
                  <Field
                    id={`isIncluded-${id}`}
                    name="isIncluded"
                    label="Do Not Include in Video"
                    type="checkbox"
                    checked={isIncluded}
                    onChange={handleChange}
                    component={FormikCheckboxField}
                  />
                </div>

                <div className="mr-4 card-checkbox">
                  <Field
                    id={`isLead-${id}`}
                    name="isLead"
                    label="Lead Broker"
                    type="checkbox"
                    checked={isLead}
                    onChange={handleChange}
                    component={FormikCheckboxField}
                  />
                </div>
                <div>
                  <Button
                    color="link"
                    size="sm"
                    className="px-0 mr-2"
                    onClick={toggleDialog}
                  >
                    View Profile
                  </Button>
                </div>
              </div>
            </div>
            <Row>
              <Col md={4}>
                <p className="mr-4 font-size-14">
                  Phone No:
                  <span className="ml-1">{phone ? phone : 'N/A'}</span>
                </p>
              </Col>

              <Col md={8}>
                <p className="mb-0 font-size-14 d-flex">
                  Email:
                  <span
                    className="ml-1 d-block ellipsis"
                    title={email ? email : ''}
                  >
                    {email ? email : 'N/A'}
                  </span>
                </p>
              </Col>
            </Row>
            <div
              style={{ background: '#F8F8F8' }}
              className="p-2 d-flex align-items-center rounded"
            >
              {brokerages[0]?.logoUrl && (
                <img
                  src={brokerages[0]?.logoUrl}
                  alt="logo"
                  className="object-fit-fill"
                  height={29}
                />
              )}

              <p className="ml-3 mb-0 font-size-14">{brokerages[0]?.name}</p>
            </div>
          </div>
        </div>
        <AddBroker
          toggleDialog={toggleDialog}
          modal={modal}
          brokerData={brokerData}
          refetch={refetch}
        />
      </CardBody>
    </Card>
  );
};

export default BrokerCard;
