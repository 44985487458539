import React, { useRef, useState, useCallback } from 'react';
import { Card, Col, Row, Input, Label, CardBody, Spinner } from 'reactstrap';
import { gql, useQuery } from '@apollo/client';
import { debounce } from 'lodash';
import Map from './components/Map';

const CITY_POPULATION_SEARCH = gql`
  query CityPopulationSearch($searchText: String, $limit: Int, $offset: Int) {
    cityPopulationSearch(
      searchText: $searchText
      limit: $limit
      offset: $offset
    )
  }
`;

const PointOfInterest = () => {
  const [searchText, setSearchText] = useState('');
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [selectedPlaces, setSelectedPlaces] = useState([]);
  const [flyTo, setFlyTo] = useState(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const limit = 10;
  const observer = useRef();

  const { loading, error, data, fetchMore } = useQuery(CITY_POPULATION_SEARCH, {
    variables: { searchText, limit, offset },
    skip: false,
  });

  const handleChange = useCallback(
    debounce((value) => {
      setSearchText(value);
      setOffset(0);
      setHasMore(true); // Reset pagination when search changes
    }, 300),
    []
  );

  const handleInputChange = (event) => {
    handleChange(event.target.value);
  };

  const togglePlace = (place) => {
    const isAlreadySelected = selectedPlaces.some((p) => p.id === place.id);

    if (isAlreadySelected) {
      setSelectedPlaces(selectedPlaces.filter((p) => p.id !== place.id));
    } else {
      setSelectedPlaces([...selectedPlaces, place]);
    }
  };

  const loadMore = () => {
    if (!isFetchingMore && !loading && hasMore) {
      setIsFetchingMore(true); // Start loading more
      fetchMore({
        variables: {
          offset: data?.cityPopulationSearch?.length || 0,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult?.cityPopulationSearch?.length) {
            setHasMore(false);
            return previousResult;
          }

          setIsFetchingMore(false); // Stop loading more
          return {
            ...previousResult,
            cityPopulationSearch: [
              ...previousResult.cityPopulationSearch,
              ...fetchMoreResult.cityPopulationSearch,
            ],
          };
        },
      }).finally(() => {
        setIsFetchingMore(false); // Ensure loading state is cleared
      });
    }
  };

  const lastPlaceRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMore();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  return (
    <div>
      <div className="d-flex align-items-center py-3 border-bottom">
        <b>Former NewYork State Armory</b>
        <p className="mb-0 font-size-14 ml-4">
          <span>Crexi URL:</span>{' '}
          <a href="https://crexi.com" className="ml-2">
            crexi.com
          </a>
        </p>
      </div>
      <Row>
        <Col md="5">
          <div
            className="p-2 font-size-14 mt-4"
            style={{ background: '#E8E6FC' }}
          >
            <b>Aravill St & Wigwarm Ave Las Vegas NV 89139</b>
          </div>
          <div>
            <p className="mb-0 mt-3">Place Names</p>
            <hr className="m-0" />
            <Input
              type="text"
              placeholder="Search"
              className="w-50 my-2"
              size="sm"
              onChange={handleInputChange}
            />
            <hr className="m-0" />

            <div
              style={{ maxHeight: 'calc(100vh - 264px)', overflowY: 'auto' }}
              className="overflow-auto"
            >
              {data?.cityPopulationSearch?.map((place) => (
                <Row
                  key={place.id}
                  className="align-items-center py-2 border-bottom m-0"
                >
                  <Col xs="1">
                    <Input
                      type="checkbox"
                      checked={selectedPlaces.some((p) => p.id === place.id)}
                      onChange={() => togglePlace(place)}
                      className="position-relative m-0"
                      style={{ top: '3px' }}
                    />
                  </Col>
                  <Col xs="6">
                    <Label className="form-check-label">{place.name}</Label>
                  </Col>
                  <Col xs="5">
                    <div className="text-muted">
                      Population: {place.population}
                    </div>
                    <p className="mb-0 text-muted" style={{ fontSize: '10px' }}>
                      {place.remark}
                    </p>
                  </Col>
                </Row>
              ))}
              {loading && (
                <div className="text-center py-3">
                  <Spinner color="primary" />
                </div>
              )}
              {!loading && hasMore && (
                <div ref={lastPlaceRef} style={{ height: '1px' }} />
              )}
              {isFetchingMore && (
                <div className="text-center py-3">
                  <div>
                    <Spinner color="primary" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Col>
        <Col md="7" className="bg-grey-upload p-4">
          <Card>
            <CardBody>
              <Map
                markers={selectedPlaces}
                onFlyTo={flyTo}
                fitBounds={selectedPlaces}
                selectedPlaces={selectedPlaces}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PointOfInterest;
