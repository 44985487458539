import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';

import Header from 'pages/PMPortal/common/Header';
import VideoReviewHeader from 'components/VideoReviewHeader';
import ErrorBoundary from 'common/ErrorBoundary';
import NoData from 'common/NoData';
import PageLoader from 'common/PageLoader';
import {
  LIST_ASSET_VERSIONS,
  GET_REVIEWED_PROJECT_HEADER_DETAILS,
} from 'containers/AppContainer/AppGraphQL';
import EditorComments from './components/EditorComments';

const useGetVersionsList = () => {
  const { folderId } = useParams();
  const { data, loading, error } = useQuery(LIST_ASSET_VERSIONS, {
    fetchPolicy: 'network-only',
    variables: {
      folderId: parseInt(folderId, 10),
    },
  });

  return { versionLoading: loading, versionError: error, versionData: data };
};

const VideoReview = () => {
  const [selectedVersion, setVersion] = useState(null);

  const { folderId } = useParams();
  const { versionData } = useGetVersionsList(folderId);
  const { data, loading, error } = useQuery(
    GET_REVIEWED_PROJECT_HEADER_DETAILS,
    {
      fetchPolicy: 'network-only',
      variables: {
        folderId: parseInt(folderId, 10),
        version: selectedVersion ? selectedVersion.version : '',
      },
    }
  );

  const handleSelectVersion = (version) => {
    setVersion(version);
  };

  // useEffect(() => {
  //   if (
  //     versionData &&
  //     versionData.listAssetVersions &&
  //     versionData.listAssetVersions.length
  //   ) {
  //     const { listAssetVersions } = versionData;
  //     handleSelectVersion(listAssetVersions[listAssetVersions.length - 1]);
  //   }
  // }, [versionData]);

  useEffect(() => {
    if (versionData?.listAssetVersions?.length) {
      handleSelectVersion(versionData.listAssetVersions[0]);
    }
  }, [versionData]);

  if (loading) return <PageLoader />;

  if (error) return <NoData customMessage={error.message} />;

  if (
    data &&
    data.getReviewedProjectHeaderDetails &&
    versionData &&
    selectedVersion
  ) {
    const {
      getReviewedProjectHeaderDetails: {
        projectId,
        startDate,
        asanaLink,
        folderName,
        elaspedTime,
        projectName,
        frameIoLink,
        slackLink,
        hubspotLink,
        planType,
      },
    } = data;

    return (
      <>
        <Header heading="Projects" />
        <VideoReviewHeader
          projectId={projectId}
          startDate={startDate}
          asanaLink={asanaLink}
          folderName={folderName}
          projectName={projectName}
          elaspedTime={elaspedTime}
          frameIoLink={frameIoLink}
          slackLink={slackLink}
          hubspotLink={hubspotLink}
          selectedVersion={selectedVersion}
          handleSelectVersion={handleSelectVersion}
          versionData={versionData.listAssetVersions || []}
          planType={planType}
        />

        <ErrorBoundary>
          <EditorComments
            version={selectedVersion.version}
            projectId={projectId}
          />
        </ErrorBoundary>
      </>
    );
  }
  return (
    <>
      <Header />
      <NoData customMessage="No comments exist for requested asset." />
    </>
  );
};
export default VideoReview;
