import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Badge, CardImg, CardBody } from 'reactstrap';

import { IVC_FIELDS, dateTimeFormatter } from 'utils/helper';
import { useAssestDetails } from 'pages/Search/hooks';
import { TABS, verifyIVCEmptyFields } from 'pages/Search/constant';
import PropertyFeatureBlock from 'pages/Search/common/PropertyFeatureBlock';
import PropertyDetailHeader from 'pages/Search/common/PropertyDetailHeader';
import PageLoader from 'pages/Search/common/PageLoader';
import { CHECKOUT } from 'pages/Search/CrexiSearchGraphQL';
import LoadingButton from 'common/LoadingButton';
import { changeEliteAuction } from 'pages/Search/SalesActions';
import { assetDetailContext } from 'contexts/AssetDetailContext';
import { getPlanContext } from 'contexts/GetPlanContext';
import CityCalloutsList from './components/CityCalloutsList';
import AddMoreButton from './components/AddMoreButton';
import OrderSummary from './components/OrderSummary';
import ConfirmationDialog from './components/ConfirmationDIalog';
import CheckoutWarningModal from './components/CheckoutWarningModal';

const Summary = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    searchedBy,
    plan,
    eliteAuction: selectedEliteAuction,
  } = useSelector((state) => state.salesPortal);

  const { isIVCDisabled } = useContext(assetDetailContext);

  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [openCheckoutWarningModal, toggleCheckoutWarningModal] =
    useState(false);
  const [isSubmiting, setSubmiting] = useState(false);

  const { planData } = useContext(getPlanContext);
  const defaultPlan = planData && planData.defaultPlan;
  const { loading, data, refetch } = useAssestDetails();

  const toggleConfirmation = (status) => {
    setModalConfirmation(status);
  };

  const handleGotIt = () => {
    setModalConfirmation(false);
    history.push(searchedBy);
  };

  const [checkoutMutation, { loading: loading2 }] = useMutation(CHECKOUT, {
    onCompleted: async ({ checkout }) => {
      setSubmiting(false);
      toggleConfirmation(true);
      dispatch(
        changeEliteAuction({
          eliteAuction: '',
        })
      );
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  if (loading) return <PageLoader />;
  const getAssetDetails = data && data.getAssetDetails;

  const {
    nearbyCallouts,
    nearbyCities,
    interiorVideoContact,
    asset,
    callToAction,
    eliteAuction,
    auction,
  } = getAssetDetails;

  const { id, name, companyNames, thumbnailUrl, locations, details } = asset;

  const getPropertyAddress = () => {
    if (locations && locations.length) {
      const { address, city, county, state, zip } = locations[0];
      const propertyAddress = `${address}, ${city}, ${
        state ? `${state.name}, ` : ''
      } ${county},  ${zip}`;
      return propertyAddress;
    }
    return null;
  };

  const addMore = (index) => {
    const tab = TABS[index];
    const { link } = tab;
    history.push(`/crexi-search/property-detail/${id}${link}`);
  };

  const checkout = async () => {
    setSubmiting(true);
    await checkoutMutation({
      variables: {
        assetId: id,
        planId: plan?.id ?? defaultPlan.id,
        eliteAuction: !eliteAuction ? selectedEliteAuction : eliteAuction,
        planName: plan?.type ?? 'PLATINUM',
      },
    });
  };

  const handleCheckout = async () => {
    if (!eliteAuction && !selectedEliteAuction) {
      toggleCheckoutWarningModal(true);
      return;
    }
    checkout();
  };

  const closeCheckoutWarningModal = () => {
    toggleCheckoutWarningModal(false);
  };

  const renderCompanyNames = () => {
    return companyNames.map((row, index) => {
      if (index > 0) {
        return <span key={row}>, {row}</span>;
      }
      return <span key={row}>{row}</span>;
    });
  };

  const renderInteriorVideoContact = () => {
    const hasAllEmptyField = verifyIVCEmptyFields(
      interiorVideoContact,
      IVC_FIELDS
    );
    return (
      <Col sm="12">
        <hr className="dotted-border-top mt-2" />
        <h6 className="font-weight-bold summery-heading mb-2">
          Interior Video Contact
        </h6>

        {!hasAllEmptyField
          ? IVC_FIELDS.map((row) => {
              if (row === 'shootDate') {
                return (
                  <Badge
                    key={row}
                    color="light"
                    pill
                    className="mr-2 filter-badge"
                  >
                    {interiorVideoContact[row]
                      ? dateTimeFormatter(interiorVideoContact[row])
                      : ''}
                  </Badge>
                );
              }
              return (
                <Badge
                  key={row}
                  color="light"
                  pill
                  className="mr-2 filter-badge"
                >
                  {interiorVideoContact[row]}
                </Badge>
              );
            })
          : 'No data entered!'}
      </Col>
    );
  };

  const handleCheckoutDisable = () => {
    // if (isIVCDisabled) {
    //   return true;
    // }
    if (loading2 || isSubmiting) {
      return true;
    }
    return false;
  };

  const renderMessage = () => {
    return (
      <Card className="mt-3">
        <CardBody>
          <p className="">
            Interior video contact is mandatory to fill with {plan.type} plan.
            <br />{' '}
            <strong className="mt-2 d-block">
              Go to Interior video contact
            </strong>
          </p>
        </CardBody>
      </Card>
    );
  };

  const AuctionBadges = ({ auction }) => {
    if (!auction?.auction) return 'No data entered!';

    return Object.values(auction.auction).map((value) => (
      <Badge className="badge-light badge-pill filter-badge mr-2" key={value}>
        {value}
      </Badge>
    ));
  };

  return (
    <>
      <PropertyDetailHeader
        fieldKey="summary"
        values={{}}
        getAssetDetails={getAssetDetails}
        isValid
        refetch={refetch}
        {...props}
      />
      <div className="themed-container property-detail-page">
        <div className="search-result-header ">
          <Card className="shadow border-radius-8px summery-card border-0">
            <CardBody className="px-3">
              <Row>
                <Col xl="9" className="border-right">
                  <Row>
                    <Col lg="3">
                      <CardImg
                        top
                        width="100%"
                        src={thumbnailUrl}
                        alt={name}
                        className="border-radius-8px"
                      />
                    </Col>
                    <Col lg="9" className="summery-add-property">
                      <h5 className="add-heading mt-lg-0 mt-3">{name}</h5>
                      <p
                        title={renderCompanyNames()}
                        className="mb-4 text-muted text-truncate card-subtitle "
                      >
                        {companyNames && companyNames.length
                          ? renderCompanyNames()
                          : 'Not Specified'}
                      </p>
                      <div className="mt-auto">
                        <Row>
                          <Col xl="8">
                            <PropertyFeatureBlock
                              heading="Address"
                              value={getPropertyAddress()}
                            />
                          </Col>
                          <Col xl="4">
                            <PropertyFeatureBlock
                              heading="PROPERTY TYPE"
                              value={
                                details && details.propertyType
                                  ? details.propertyType
                                  : ''
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col sm="12">
                      <hr className="dotted-border-top" />
                      <Row className="mb-2">
                        <Col md="auto">
                          <h6 className="font-weight-bold summery-heading mb-0">
                            Cities within a 200 mile radius
                          </h6>
                        </Col>
                        <Col md="auto" className="ml-auto">
                          <AddMoreButton onClick={() => addMore(1)} />
                        </Col>
                      </Row>
                      <CityCalloutsList
                        dataArray={nearbyCities}
                        fieldName="nearbyCities"
                        getAssetDetails={getAssetDetails}
                        distance={200}
                      />
                    </Col>
                    <Col sm="12">
                      <hr className="dotted-border-top mt-2" />
                      <Row className="mb-2">
                        <Col md="auto">
                          <h6 className="font-weight-bold summery-heading mb-0">
                            Callouts within 100 Miles
                          </h6>
                        </Col>
                        <Col md="auto" className="ml-auto">
                          <AddMoreButton onClick={() => addMore(2)} />
                        </Col>
                      </Row>
                      <CityCalloutsList
                        dataArray={nearbyCallouts}
                        fieldName="nearbyCallouts"
                        getAssetDetails={getAssetDetails}
                        distance={100}
                      />
                    </Col>
                    <Col sm="12">
                      <hr className="dotted-border-top mt-2" />
                      <Row className="mb-2">
                        <Col md="auto">
                          <h6 className="font-weight-bold summery-heading mb-0">
                            Callouts within 10 Miles
                          </h6>
                        </Col>
                        <Col md="auto" className="ml-auto">
                          <AddMoreButton onClick={() => addMore(3)} />
                        </Col>
                      </Row>
                      <CityCalloutsList
                        dataArray={nearbyCallouts}
                        fieldName="nearbyCallouts"
                        getAssetDetails={getAssetDetails}
                        distance={10}
                      />
                    </Col>
                    <Col sm="12">
                      <hr className="dotted-border-top mt-2" />
                      <h6 className="font-weight-bold summery-heading mb-2">
                        Auction Dates and Broker
                      </h6>

                      <AuctionBadges auction={auction} />
                    </Col>
                    <Col sm="12">
                      <hr className="dotted-border-top mt-2" />
                      <h6 className="font-weight-bold summery-heading mb-2">
                        Call to Action
                      </h6>
                      {callToAction ? (
                        <Badge className="badge-light badge-pill filter-badge mr-2">
                          {callToAction.text}
                        </Badge>
                      ) : (
                        'No data entered!'
                      )}
                    </Col>
                    {renderInteriorVideoContact()}
                  </Row>
                </Col>
                <Col xl="3" className="order-table">
                  <OrderSummary />
                  <Row>
                    <Col sm="12">
                      <LoadingButton
                        block
                        loading={isSubmiting}
                        className="proceed-btn"
                        disabled={handleCheckoutDisable()}
                        onClick={handleCheckout}
                      >
                        Submit Order
                      </LoadingButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
        <ConfirmationDialog
          modalConfirmation={modalConfirmation}
          setModalConfirmation={setModalConfirmation}
          handleGotIt={handleGotIt}
        />
        <CheckoutWarningModal
          propertyName={name}
          isVisibleModal={openCheckoutWarningModal}
          closeCheckoutWarningModal={closeCheckoutWarningModal}
        />
      </div>
    </>
  );
};

export default Summary;
